import { StatusCodes } from 'http-status-codes';

import { appConfig } from '../config';
import axios from './axiosInstance';
import { getAuthorization, handlingResponse, logError } from './utils';

export const updateStatus = ({ entityId, status }) => axios
  .put(
      `${appConfig.backendUrl}/api/v1/auditors/${entityId}/status`,
      { auditor_status: status },
      { headers: { Authorization: getAuthorization() } },
  )
  .then(handlingResponse([StatusCodes.OK], `Error updating auditor ${entityId} status`))
  .catch(logError);

export const updateGeolocation = current_coordinates => axios
  .put(
      `${appConfig.backendUrl}/api/v1/auditors/me/coordinates`,
      { current_coordinates },
      { headers: { Authorization: getAuthorization() } },
  )
  .then(handlingResponse([StatusCodes.OK], 'Error updating geolocation'))
  .catch(logError);

export const enableNotifications = notification => axios
  .put(
      `${appConfig.backendUrl}/api/v1/auditors/me/notifications`,
      { enable_notifications: notification },
      { headers: { Authorization: getAuthorization() } },
  )
  .then(handlingResponse([StatusCodes.OK], 'Error updating notifications setting'))
  .catch(logError);

export const tooltip = ({ entityId }) => axios
  .get(`${appConfig.backendUrl}/api/v1/auditors/${entityId}/tooltip`, {
    headers: { Authorization: getAuthorization() },
  })
  .then(handlingResponse([StatusCodes.OK], `Error fetching auditor's ${entityId} tooltip`))
  .catch(logError);

export const fetchAllForMap = async () => {
  try {
    const route = `${appConfig.backendUrl}/api/v1/auditors/map`;
    const response = await axios.get(route, {
      headers: { Authorization: getAuthorization() },
    });
    return handlingResponse([StatusCodes.OK], 'Error fetching auditors for Map')(response);
  } catch (error) {
    await logError(error);
    return null;
  }
};

export const fetchDetailsForMap = async ({ version = '1', id }) => {
  try {
    const route = `${appConfig.backendUrl}/api/v${version}/auditors/${id}/map-tooltip`;
    const response = await axios.get(route, {
      headers: { Authorization: getAuthorization() },
    });
    return handlingResponse([StatusCodes.OK], 'Error fetching auditors for Map')(response);
  } catch (error) {
    await logError(error);
    return null;
  }
};
