import { StatusCodes } from 'http-status-codes';

import { appConfig } from '../config';
import { stringify } from '../utils/queryString';
import axios from './axiosInstance';
import { getAuthorization, handlingResponse, logError } from './utils';

export const updateStatus = ({ entityId, status }) => axios
  .put(
    `${appConfig.backendUrl}/api/v1/audits/${entityId}/status`,
    { status },
    { headers: { Authorization: getAuthorization() } },
  )
  .then(
    handlingResponse(
      [StatusCodes.OK],
      `Error updating audit ${entityId} status`,
    ),
  )
  .catch(logError);
export const reset = ({ entityId, reassign, keepData }) => axios
  .put(
    `${appConfig.backendUrl}/api/v1/audits/${entityId}/reset?reassign=${reassign}&keep_data=${keepData}`,
    {},
    { headers: { Authorization: getAuthorization() } },
  )
  .then(
    handlingResponse([StatusCodes.OK], `Error reseting audit ${entityId}`),
  )
  .catch(logError);
export const amend = ({ entityId, payload }) => axios
  .put(
    `${appConfig.backendUrl}/api/v1/audits/${entityId}/amend`,
    { payload },
    { headers: { Authorization: getAuthorization() } },
  )
  .then(
    handlingResponse(
      [StatusCodes.OK],
      `Error amending audit ${entityId} survey answers`,
    ),
  )
  .catch(logError);
export const sendFailLetterNotification = async ({ entityId, payload }) => axios
  .post(
    `${appConfig.backendUrl}/api/v1/audits/${entityId}/fail-letter-notification`,
    payload,
    { headers: { Authorization: getAuthorization() } },
  )
  .then(
    handlingResponse(
      [StatusCodes.OK],
      `Error sending fail letter notification for audit ${entityId}`,
    ),
  )
  .catch(logError);
export const bulkPdf = async ({ payload }) => axios({
  method: 'post',
  url: `${appConfig.backendUrl}/api/v1/audits/bulk/pdf`,
  data: payload,
  responseType: 'blob',
  headers: { Authorization: getAuthorization() },
})
  .then(handlingResponse([StatusCodes.OK], 'Error bulking audits PDFs'))
  .catch(logError);

export const fetchAll = version => async (queryObject = {}) => {
  try {
    let route = `${appConfig.backendUrl}/api/v${version}/audits`;
    const query = stringify(queryObject);
    if (query.length > 0) {
      route = route.concat('?', query);
    }
    const response = await axios.get(route, {
      headers: { Authorization: getAuthorization() },
    });
    return handlingResponse(
      [StatusCodes.OK],
      'Error fetching audits',
    )(response);
  } catch (error) {
    await logError(error);
    return null;
  }
};
export const fetchAssignedByAuditorId = ({ auditorId }) => axios
  .get(`${appConfig.backendUrl}/api/v1/audit/assigned/${auditorId}`, {
    headers: { Authorization: getAuthorization() },
  })
  .then(
    handlingResponse(
      [StatusCodes.OK],
      `Error fetching assigned audits for auditor ${auditorId}`,
    ),
  )
  .catch(logError);

export const fetchAllForMap = async (filterValues = {}) => {
  try {
    let route = `${appConfig.backendUrl}/api/v1/audits/map`;
    const query = stringify(filterValues);
    if (query.length > 0) {
      route = route.concat('?', query);
    }
    const response = await axios.get(route, {
      headers: { Authorization: getAuthorization() },
    });
    return handlingResponse(
      [StatusCodes.OK],
      'Error fetching audits for Map',
    )(response);
  } catch (error) {
    await logError(error);
    return null;
  }
};

export const fetchAuditTooltip = async ({ id }) => {
  try {
    const response = await axios.get(
      `${appConfig.backendUrl}/api/v1/audits/${id}/map-tooltip`,
      {
        headers: { Authorization: getAuthorization() },
      },
    );
    return handlingResponse(
      [StatusCodes.OK],
      `Error fetching audit ${id} tooltip`,
    )(response);
  } catch (error) {
    await logError(error);
    return null;
  }
};
