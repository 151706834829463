import { countries } from 'country-codes-flags-phone-codes';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useMutation } from 'react-query';
import { Button, Form, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';

import { errorAPI, multipartAPI } from '../../../../api';
import { Entity } from '../../../../constants';
import { formatPhoneNumber } from '../../../../utils/mobileHelpers';
import { validateMobileNumber } from '../../../../utils/validationHelpers';
import { FormFieldSelectWrapper, FormFieldWrapper } from '../../../common';
import styles from './DashboardMobilePhoneNumberModal.module.scss';

const mobilePhonenumberValidation = Yup.object().shape({
  mobile_number: Yup.string()
    .required('Please enter your mobile number').test('valid-phone', 'Please enter a valid mobile number', validateMobileNumber),
  mobile_prefix: Yup.string()
    .required('Please select your prefix'),
});

const sortedCountries = countries
  .filter(country => country.name !== 'United Kingdom' && country.name !== 'Ireland')
  .sort((a, b) => a.name.localeCompare(b.name));

export const countryPrefixes = [
  countries.find(country => country.name === 'United Kingdom'),
  countries.find(country => country.name === 'Ireland'),
  ...sortedCountries,
];

const DashboardMobilePhoneNumberModal = ({ onAccept, userInfo }) => {
  const { mutate: editAuditorAccount } = useMutation(multipartAPI.update, {
    onSuccess: () => {
      onAccept();
    },
    onError: error => {
      errorAPI.sendError(error.message, error.stack);
    },
  });

  const updateAuditorInfo = data => {
    const formData = new FormData();

    const { mobile_prefix, mobile_number, account_number } = data;

    Object.entries({
      mobile_prefix,
      mobile_number,
      account_number,
    }).forEach(([key, value]) => formData.append(key, value));

    return editAuditorAccount({
      entity: Entity.AUDITORS,
      entityId: 'me',
      payload: formData,
      version: 'v2',
    });
  };

  return (
    <Modal className="phoneMobileNumber" closeOnDimmerClick={true} open={true} size="tiny">
      <Modal.Header>
        {'Information Required'}
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={{
            ...userInfo,
            mobile_number: '',
          }}
          validationSchema={mobilePhonenumberValidation}
          onSubmit={updateAuditorInfo}
        >
          {({ handleSubmit, values, setFieldValue }) => {
            const handlePhoneChange = value => {
              const rawValue = value;
              setFieldValue('mobile_number_raw', rawValue);
              const formattedNumber = formatPhoneNumber(values.mobile_prefix, rawValue);
              setFieldValue('mobile_number', formattedNumber);
            };

            return (
              <Form onSubmit={handleSubmit}>
                <div>
                  <span className={styles.titleQuestion}>
                    {'Please re-enter your mobile phone number:'}
                  </span>
                  <FormFieldSelectWrapper
                    required
                    name="mobile_prefix"
                    options={countryPrefixes.map(country => ({
                      value: `${country.code}-${country.dialCode}`,
                      text: `${country.flag} ${country.name} (${country.dialCode})`,
                    }))}
                    placeholder="Select country code"
                  />
                  <FormFieldWrapper required name="mobile_number" placeholder="Mobile number" onChange={handlePhoneChange} />
                </div>
                <Modal.Actions>
                  <Modal.Content className={styles.content}>
                    <Button primary content="Save and close" type="submit" />
                  </Modal.Content>
                </Modal.Actions>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

DashboardMobilePhoneNumberModal.propTypes = {
  userInfo: PropTypes.object.isRequired,
  onAccept: PropTypes.func.isRequired,
};

export default DashboardMobilePhoneNumberModal;
