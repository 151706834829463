import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Icon } from 'semantic-ui-react';

import { LAYER_ID } from '../helpers';
import styles from './MapTooltip.module.scss';
import AuditContent from './tooltips/AuditContent.component';
import AuditorContent from './tooltips/AuditorContent.component';

const MapTooltip = ({ data, fixed, onClose }) => {
  const { layerId, x, y, ...tooltipContent } = data;

  const type = useMemo(() => {
    const layerType = {
      [LAYER_ID.AUDITS]: {
        type: 'audit',
        icon: 'flag icon',
        title: `${tooltipContent.site_name}, ${tooltipContent.site_post_code}`,
        content: <AuditContent content={tooltipContent} />,
      },
      [LAYER_ID.AUDITORS]: {
        type: 'auditor',
        icon: 'user icon',
        title: `${tooltipContent.preferred_first_name} ${tooltipContent.preferred_last_name}`,
        content: <AuditorContent content={tooltipContent} />,
      },
    };
    return layerType[layerId] || layerType.default;
  }, [layerId, data]);

  return (
    <div
      className={`${styles.tooltipContainer} ${fixed ? styles.highlighted : ''}`}
      style={{
        left: x - 150,
        top: y + 12,
      }}
    >
      <h4>
        <span style={{ marginRight: '5px' }}>
          <i className={type.icon} />
        </span>
        <span>
          {type.title}
        </span>
      </h4>
      {fixed && <Icon className={styles.closeIcon} name="close" size="large" onClick={onClose} />}
      {type.content}
    </div>
  );
};

export default MapTooltip;

MapTooltip.propTypes = {
  data: PropTypes.object.isRequired,
  fixed: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
