import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';

import { enumOptionsSelector } from '../../../../../state/constants/selectors';
import { generateWhatsAppLink } from '../../../../../utils/mobileHelpers';
import styles from '../MapTooltip.module.scss';

const getIconName = status => (status ? 'check' : 'close');

const AuditorContent = ({ content }) => {
  const { briefed_for_narv, briefed_for_ssl, right_to_work,
    home_post_code, term_post_code, age, sl_visit_count, narv_visit_count,
    transport_available, mobile_prefix, mobile_number, pass_rate } = content;

  const transportAvailableOptionSelector = state => enumOptionsSelector(state, 'transport_available_type');
  const transportAvailableOption = useSelector(transportAvailableOptionSelector);

  const mainData = [
    { label: 'Home postcode', value: home_post_code },
    { label: 'Term postcode', value: term_post_code },
    { label: 'Age', value: age },
    { label: 'SSL', value: sl_visit_count },
    { label: 'NARV', value: narv_visit_count },
    { label: 'Access to car', value: transportAvailableOption[transport_available] },
  ];

  const extraInfoSection = [
    { label: 'RTW', value: right_to_work },
    { label: 'SSL', value: briefed_for_ssl },
    { label: 'NARV', value: briefed_for_narv },
  ];

  return (
    <div className={styles.tooltipContent}>
      {
        mainData.map((item, index) => (
          <p key={`auditor-tooltip-data-${index}`}>
            <strong>
              {item.label}
:
            </strong>
            {item.value}
          </p>
        ))
      }
      <p>
        <strong>
Pass Rate:
        </strong>
        <span className={pass_rate >= 5 ? styles.success : styles.error}>
          {pass_rate !== null && `${pass_rate}%`}
        </span>
      </p>
      <section className={styles.auditorExtraInfo}>
        <ul className={styles.checks}>
          {extraInfoSection.map((item, index) => (
            <li key={`auditor-tooltip-extra-info-${index}`} className={item.value ? styles.success : styles.error}>
              <Icon name={getIconName(item.value)} />
              {item.label}
            </li>
          ))}
        </ul>
        <div className={styles.ctaContainer}>
          <Button className={styles.whatsAppBtn} type="button">
            <a href={generateWhatsAppLink(mobile_prefix, mobile_number)}>
              <Icon name={'phone'}/>
              {mobile_number}
            </a>
          </Button>
        </div>
      </section>
    </div>
  );
};

export default AuditorContent;

AuditorContent.propTypes = {
  content: PropTypes.shape({
    age: PropTypes.string,
    briefed_for_narv: PropTypes.bool,
    briefed_for_ssl: PropTypes.bool,
    home_post_code: PropTypes.string,
    mobile_number: PropTypes.string,
    mobile_prefix: PropTypes.string,
    narv_visit_count: PropTypes.number,
    pass_rate: PropTypes.number,
    right_to_work: PropTypes.bool,
    sl_visit_count: PropTypes.number,
    term_post_code: PropTypes.string,
    transport_available: PropTypes.string,
  }).isRequired,
};
