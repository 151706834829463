import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { auditorsAPI, auditsAPI } from '../../../../api';
import { mapFeatureEnabledSelector } from '../../../../state/auditTableView/selectors';
import { parse } from '../../../../utils/queryString';
import { createIconLayer, LAYER_ID } from '../helpers';
import AuditorMapControl from '../MapControls/AuditorMapControl.component';
import MapRenderer from '../MapRenderer/MapRenderer.component';
import MapTooltip from '../MapTooltip/MapTooltip.component';
import styles from './AuditsMap.module.scss';

const parseQueryParams = queryStringValue => {
  const currentQuery = parse(queryStringValue);
  const { page, sort, order, limit, ...filterValues } = currentQuery;
  return filterValues || {};
};

const AuditsMap = () => {
  const location = useLocation();
  const mapView = useSelector(mapFeatureEnabledSelector);
  const [showAuditors, setShowAuditors] = useState(false);
  const [tooltipInfo, setTooltipInfo] = useState(null);
  const [tooltipFixed, setTooltipFixed] = useState(false);

  const { data: mapAudits } = useQuery(
    ['auditsMap', location.search],
    async () => {
      const res = await auditsAPI.fetchAllForMap(parseQueryParams(location.search));
      return res.audits.filter(d => d.site_coordinates);
    },
    {
      enabled: mapView,
      staleTime: 5 * 60 * 1000,
    },
  );

  const { data: mapAuditors } = useQuery(
    'fetchAuditorsForMap',
    async () => {
      const data = await auditorsAPI.fetchAllForMap();
      return data.items.filter(d => d.home_coordinates);
    },
    {
      enabled: mapView && showAuditors,
      staleTime: 15 * 60 * 1000,
    },
  );

  const enableAuditorData = !!tooltipInfo?.object?.id && tooltipInfo.layerId === LAYER_ID.AUDITORS;

  const { data: auditorTooltipInfo } = useQuery(
    ['auditorTooltip', tooltipInfo?.object?.id],
    () => auditorsAPI.fetchDetailsForMap({ id: tooltipInfo?.object?.id }),
    {
      enabled: enableAuditorData,
    },
  );

  const enableAuditsData = !!tooltipInfo?.object?.id && tooltipInfo.layerId === LAYER_ID.AUDITS;

  const { data: auditsTooltipInfo } = useQuery(
    ['auditsTooltip', tooltipInfo?.object?.id],
    () => auditsAPI.fetchAuditTooltip({ id: tooltipInfo?.object?.id }),
    {
      enabled: enableAuditsData,
    },
  );

  const handleHover = useCallback(info => {
    if (info.object && !tooltipFixed) {
      setTooltipInfo({
        object: info.object,
        x: info.x,
        y: info.y,
        layerId: info.layer.id,
      });
    } else if (!info.object && !tooltipFixed) {
      setTooltipInfo(null);
    }
  }, [tooltipFixed]);

  const handleClick = useCallback(info => {
    if (!info.object?.id) return;
    if (info.object.id === tooltipInfo?.object?.id) {
      setTooltipFixed(prev => !prev);
    } else {
      setTooltipInfo({
        object: info.object,
        x: info.x,
        y: info.y,
        layerId: info.layer.id,
      });
      setTooltipFixed(true);
    }
  }, [tooltipInfo?.object?.id]);

  const auditsLayer = useMemo(
    () => createIconLayer({ id: LAYER_ID.AUDITS,
      items: mapAudits,
      layerProps: { onHover: handleHover, onClick: handleClick } }),
    [mapAudits, handleHover, handleClick],
  );
  const auditorsLayer = useMemo(() => createIconLayer({ id: LAYER_ID.AUDITORS,
    items: mapAuditors,
    layerProps: {
      getPosition: a => [a.home_coordinates.lng, a.home_coordinates.lat, 0],
      getColor: () => [255, 0, 0, 255],
      visible: showAuditors,
      onHover: handleHover,
      onClick: handleClick,
    } }), [mapAuditors, showAuditors, handleHover, handleClick]);

  const layers = useMemo(() => {
    const activeLayers = [];
    if (auditsLayer) activeLayers.push(auditsLayer);
    if (auditorsLayer) activeLayers.push(auditorsLayer);
    return activeLayers;
  }, [auditsLayer, auditorsLayer]);

  return (
    <div className={styles.mapContainer}>
      <MapRenderer layers={layers}>
        {(auditorTooltipInfo || auditsTooltipInfo) && (
          <MapTooltip
            data={{
              ...tooltipInfo,
              ...auditorTooltipInfo,
              ...auditsTooltipInfo,
            }}
            fixed={tooltipFixed}
            onClose={() => {
              setTooltipInfo(null);
              setTooltipFixed(false);
            }}
          />) }
        <AuditorMapControl setShowAuditors={setShowAuditors} showAuditors={showAuditors} />
      </MapRenderer>
    </div>
  );
};

export default AuditsMap;
