import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import styles from '../MapTooltip.module.scss';

const AUDIT_RESULT = {
  PASS: 'pass',
  FAIL: 'fail',
};

const AuditContent = ({ content }) => {
  const { object, item_to_order, audit_history } = content;

  return (
    <div className={styles.tooltipContent}>
      <p>
        <strong>
Item to Order:
        </strong>
        {item_to_order}
      </p>
      <p>
        <strong>
Order Deadline:
        </strong>
        {object?.deadline_date && format(new Date(object?.deadline_date), 'dd/MM/yyyy')}
      </p>
      <div className={styles.auditorsContainer}>
        <strong>
Last 3 Auditors:
        </strong>
        <div className={styles.auditors}>
          {audit_history?.length > 0
            && audit_history.map(auditor => (
              <span
                key={auditor?.audit_id}
                className={auditor?.result === AUDIT_RESULT.PASS ? styles.success : styles.error}
              >
                <span style={{ marginRight: '5px' }}>
                  <i className={auditor?.result === AUDIT_RESULT.PASS ? 'check icon' : 'times icon'} />
                </span>
                {auditor?.auditor_name}
,
                {auditor?.date_of_visit && format(new Date(auditor?.date_of_visit), 'dd/MM/yyyy')}
              </span>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AuditContent;

AuditContent.propTypes = {
  content: PropTypes.shape({
    audit_history: PropTypes.arrayOf(
      PropTypes.shape({
        audit_id: PropTypes.string,
        auditor_name: PropTypes.string,
        date_of_visit: PropTypes.string,
        result: PropTypes.string,
      }),
    ),
    item_to_order: PropTypes.string,
    object: PropTypes.shape({
      deadline_date: PropTypes.string,
    }),
  }).isRequired,
};
